import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Lingo",
  "date": "2016-03-25",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "https://www.subtraction.com/wp-content/uploads/2016/03/2016-03-24-lingo.png",
        "alt": null
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://www.subtraction.com/2016/03/24/lingo-by-noun-project/"
      }}>{`Khoi Vinh, writing about Lingo by Noun Project`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Designers can drag and drop their assets into Lingo’s thumbnail browser interface; the assets are synced to Lingo’s own cloud service and are then accessible across computers. `}</p>
    </blockquote>
    <p>{`This looks very promising.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      